import React, {useEffect, useState} from "react";
import Pagination from "./components/Pagination";
import "../../styles/CarBooking/CarBookingCalendar.scss"
import CalendarTable from "./components/CalendarTable";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import ContentLoader from "../../components/ContentLoader";
import {checkBookingCarExelFileRequest} from "./api";
import {getDomain} from "../../functions/functions";


const CarBookingCalendar = () => {
    const [data, setData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loading, setLoading] = useState(() => <ContentLoader/>)
    const [alert, setAlert] = useState({})

    const DAYS_TO_VIEW = 7;

    useEffect(() => {
        //console.log(currentDate);

        const date = new Date(currentDate);
        date.setDate(date.getDate() + DAYS_TO_VIEW);
        //console.log(format(currentDate, "dd.MM.yyyy"));
        setLoading(false)
    }, [currentDate]);

    // получение четырех дат для бронирования, потом их фильтровать нада
    const getDates = (startDate) => {
        return Array.from({length: DAYS_TO_VIEW}, (_, i) => {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            return format(date, "dd MMMM yyyy 'г.'", {locale: ru});
        });
    };

    useEffect(() => {
        const endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() + DAYS_TO_VIEW);
        const curr = format(currentDate, "dd.MM.yyyy");

        fetch(`${process.env.REACT_APP_BASE_URL}/api/booking/car/?booking_time_after=${curr}&booking_time_before=${format(endDate, "dd.MM.yyyy")}`)
            .then(response => {
                if (response.ok) {
                    response.json().then((data) => {
                        setData(data.results);
                    })
                }
            })
    }, [currentDate])

    if (loading) {
        return (
            <div className="table-wrap">
                <div>{loading}</div>
            </div>
        )
    } else return (
        <div className="car-booking-calendar-container">
            <Pagination onDateChange={setCurrentDate}/>

            {<CalendarTable data={data} dates={getDates(currentDate)}/>}
        </div>
    )
}

export default CarBookingCalendar;
