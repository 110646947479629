import {getDomain} from "../../../functions/functions";

export default async function getBookingCarDetail(id, options = false) {
    const url = getDomain() + `/api/booking/car/${id}`
    const response = await fetch(url, {
        method: options ? 'OPTIONS' : 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })
    if (response.ok) {
        return response.json()
    }
    return null
}