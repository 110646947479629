import {getDomain} from "../../../functions/functions";

export default async function checkClientShowExcelFileRequest(request) {
    const url = getDomain() + `/api/booking/car/generate_excel`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })
    return !!response.ok
}