import React, {useEffect, useState} from "react";
import Alert from "../../components/Allert";
import FormItem from "../../components/form/FormItem";
import {DateTimeField, TimeSwitcher} from "../CalendarBooking/components";
import ContentLoader from "../../components/ContentLoader";
import {useNavigate} from "react-router-dom";
import {getItems} from "../../functions/functions";

import HeaderInside from "../../components/HeaderInside";
import FormErrorsContext from "../../functions/formErrorsContext";
import AddedFormItem from "../../components/form/AddedFormItem";
import {getBookingCarList, patchBookingCar, postBookingCar} from "./api";
import {getCurrentTime} from "../../functions/getCurrentTime";
import {isCurrentDateTime} from "../../functions/isCurrentDateTime";
import postAutoBookingCar from "./api/postAutoBookingCar";

const CarBookingTaxi = (props) => {
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const [formErrors, setFormErrors] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            const options = (await getBookingCarList({options: true}))?.actions?.POST
            const newFields = {}
            const keys = Object.keys(options || {})
            keys.forEach((key) => {
                newFields[key] = {...options[key], value: ''}
            })
            setFields(newFields)
        }

        getFields()
    }, [])

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    function isDifference30Minutes(time1, time2) {
        const [hours1, minutes1] = time1.split(':').map(Number);
        const [hours2, minutes2] = time2.split(':').map(Number);

        // Преобразуем время в минуты с начала дня
        const totalMinutes1 = hours1 * 60 + minutes1;
        const totalMinutes2 = hours2 * 60 + minutes2;

        // Проверяем разницу ровно в 30 минут
        return Math.abs(totalMinutes1 - totalMinutes2) >= 30;
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const obj = {...Object.fromEntries(formData.entries())}
        const time = parseInt(obj.booking_time.slice(-5).slice(0, 2))
        const currentTime = getCurrentTime()

        if (time >= 9 && time <= 18 && (time + parseInt(obj.booking_duration) <= 18)) {

            // если это текущий день
            if (isCurrentDateTime(obj.booking_time) && !isDifference30Minutes(obj.booking_time.slice(-5), currentTime)) {
                setAlert({
                    type: 'error',
                    title: 'Не удалось забронировать поездку',
                    text: 'Бронирование возможно за 30 минут от текущего времени'
                })
                return
            }

            const response = await postAutoBookingCar(obj)

            if (response?.ok) {
                setAlert({type: 'success', title: 'Успешно'})
                navigate('/car-booking')
            } else {
                if (response?.error?.[0] === "Время бронирования пересекается с другими бронированиями.") {
                    setAlert({
                        type: 'error',
                        title: 'Не удалось забронировать поездку',
                        text: 'На данное время уже нет свободных автомобилей'
                    })
                } else {
                    setAlert({type: 'error', title: 'Ошибка', text: response?.error || 'Ошибка сервера, повторите позднее'})
                }
            }
        } else {
            setAlert({
                type: 'error',
                title: 'Дата и время начала поездки',
                text: "Рабочее время с 9:00 до 18:00"
            })
        }
    }

    const duration = [
        {value: '1', display_name: '1', label: '1 час'},
        {value: '2', display_name: '2', label: '2 часа'},
        {value: '3', display_name: '3', label: '3 часа'},
        {value: '4', display_name: '4', label: '4 часа'},
        {value: '5', display_name: '5', label: '5 часов'},
        {value: '6', display_name: '6', label: '6 часов'},
        {value: '7', display_name: '7', label: '7 часов'},
        {value: '8', display_name: '8', label: '8 часов'},
        {value: '9', display_name: '9', label: '9 часов'},
    ]
    const booking_types = [{value: 'Служебная поездка', display_name: 'Служебная поездка', label: 'Служебная поездка'},
        {value: 'Обслуживание', display_name: 'Обслуживание', label: 'Обслуживание'},
        {value: 'Мойка', display_name: 'Мойка', label: 'Мойка'}]

    return (
        <div className={"page"}>
            <>
                <HeaderInside title={'Бронирование автомобиля'}/>
                <hr/>
                <div className="page__content">
                    {!fields ? <ContentLoader/>
                        : <form className={"form-container"} onSubmit={onSubmit}>
                            <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
                                {fields?.booking_time &&
                                    <DateTimeField startValue={fields.booking_time.value} name={'booking_time'}
                                                   label={'Дата и время начала поездки'} required={true}/>}
                                {fields?.trip_type &&
                                    <FormItem field={{
                                        ...fields.trip_type,
                                        children: booking_types,
                                        type: "select",
                                        name: 'trip_type',
                                        placeholder: 'Выберите ',
                                    }}/>}
                                {fields?.booking_duration &&
                                    <FormItem field={{
                                        ...fields.booking_duration,
                                        children: duration,
                                        type: "select",
                                        name: 'booking_duration',
                                        placeholder: 'Выберите ',
                                    }}/>}
                                {fields?.start_address && <FormItem field={{
                                    ...fields.start_address,
                                    type: 'text',
                                    name: 'start_address',
                                    placeholder: 'Костюкова 46Б',
                                    value: 'Костюкова 46Б'
                                }} classes="_full"/>}
                                {fields?.end_address && <FormItem field={{
                                    ...fields.end_address,
                                    type: 'text',
                                    name: 'end_address',
                                    placeholder: 'Костюкова 46Б',
                                    value: 'Костюкова 46Б'
                                }} classes="_full"/>}
                                {fields?.responsible &&
                                    <FormItem field={{
                                        ...fields.responsible,
                                        children: convertChoices(fields.responsible.choices),
                                        type: 'select',
                                        name: 'responsible',
                                        placeholder: 'Выберите ',
                                    }} classes="_full"/>}

                                {fields?.note && <FormItem field={{
                                    ...fields.note,
                                    type: 'textarea',
                                    name: 'note',
                                    placeholder: '',
                                }} classes="_full"/>}

                                <div className="form__buttons-panel">
                                    <input type="submit" id="save_button" className="d-none" value="Send"/>
                                    <label htmlFor="save_button"
                                           className={"save_button_fixed"}>{'Сохранить'}</label>
                                </div>
                            </FormErrorsContext.Provider>
                        </form>}
                    {alert && <Alert setAlert={setAlert} alert={alert}/>}
                </div>
            </>
        </div>
    )
}

export default CarBookingTaxi;
