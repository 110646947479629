import React, {useCallback, useEffect, useRef, useState} from 'react'
import Bell from "/public/interface_img/bell.svg"
import WhiteBell from "/public/interface_img/white-bell.svg"
import Check from "/public/interface_img/check.svg"
import Delete from "/public/interface_img/delete-dark-gray.svg"
import Message from "/public/interface_img/message.svg"
import {getDomain, getItems, userHasPermission} from '../functions/functions'
import {useNavigate, useOutletContext} from 'react-router-dom'
import getHumanizedDateTime from '../functions/getHumanizedDateTime'
import {routes} from "../pages"
import getUTCDate from '../functions/getUTCDate'
import NotificationPagination from "./NotificationPagination";
import {useDispatch, useSelector} from "react-redux";

import {readNotification, readAllNotification} from '../store/tableNotification'
import {readAllNavNotification, setNavNotification} from "../store/navNotification"

import SelectItem from "./form/SelectItem";

const NotificationCenter = (props) => {
    const modal = useRef()
        , [active, setActive] = useState(false)
        , [notifications, setNotifications] = useState([])
        , [notificationsCount, setNotificationCount] = useState(0)
        , fetchNavNotifications = useOutletContext()
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
        , dispatcher = useDispatch()
        , readNotificationSignal = (notification_id) => dispatcher(readNotification(notification_id))
        , readAllNotificationSignal = () => dispatcher(readAllNotification())
        , readAllNavNotificationSignal = () => dispatcher(readAllNavNotification())
        , setNotificationClasses = (notificationClasses) => dispatcher(setNavNotification(notificationClasses))

        , [loader, setLoader] = useState(false)
        , [isNext, setIsNext] = useState(false)

        , limit = 20
        , [offset, setOffset] = useState(0)
        , [sortingModel, setSortingModel] = useState('')
        , modelOptions = [
        {value: '', label: 'Все уведомления'},
        {value: 'AnnouncementModel', label: 'Лента'},
        {value: 'ChatModel', label: 'Чаты'},
        {value: 'MeetingModel', label: 'Календарь'},
        {value: 'IncomingModel', label: 'Входящие'},
        {value: 'OutputModel', label: 'Исходящие'},
        {value: 'AgreementModel', label: 'Согласования'},
        {value: 'ServiceRecordModel', label: 'Служебки'},
        {value: 'DocReestrModel', label: 'Договоры'},
        {value: 'GeneratedDocument', label: 'Документы'},
        {value: 'TasksModel', label: 'Задачи'},
        {value: 'ClientTaskModel', label: 'Задачи клиентов', use_permissions: true},
        {value: 'ClientTaskForShowModel', label: 'Заявки на показы', use_permissions: true},
        {value: 'SupportModel', label: 'Техподдержка'},
        {value: 'AhoSupportModel', label: 'Заявки АХО'},
        {value: 'ExploitationSupportModel', label: 'Заявки отдела эксплуатации'},
        {value: 'CapSupportModel', label: 'Заявки кап. строительства'},
        {value: 'CarBookingModel', label: 'Бронирование автомобиля'},
        {value: 'CarModel', label: 'Диспетчер'}

    ];

    const filteredModelOptions = modelOptions.filter(option => {
        return userHasPermission('view_' + option.value) || !option.use_permissions
    })
    const addNotifications = (data) => {
        const dataNotifications = data.notifications
        const notificationsDataLength = dataNotifications.length

        if (notificationsDataLength + offset >= data.total_count_notifications) {
            setIsNext(false)
        } else {
            setIsNext(true)
        }

        setNotificationCount(data.total_count_notifications)
        setOffset(offset + limit)
        setNotifications(notifications.concat(dataNotifications))
        setLoader(false)
    }

    const getNotifications = () => {
        let modelFilterQuery = sortingModel ? `&model_name=${sortingModel}` : "";
        getItems(`${getDomain()}/api/notifications/?format=json&owner_id=${userData.user_id}${modelFilterQuery}&limit=${limit}&offset=${offset}`, "GET", addNotifications)
    }

    useEffect(() => {
        getNotifications()
    }, [sortingModel])

    const handleModelFilterChange = (selectedLabel) => {
        console.log("selectedLabel", selectedLabel)
        const selectedOption = filteredModelOptions.find(option => option.label === selectedLabel);
        if (selectedOption && selectedLabel.value !== sortingModel) {
            setNotifications([]);
            setOffset(0);
            setIsNext(true);
            setLoader(true);
            setSortingModel(selectedOption.value); // вызывается getNotifications
        }
    }
    const paginate = () => {
        setLoader(true)
        getNotifications()
    }

    const readAllNotifications = () => {
        getItems(`${getDomain()}/api/notifications/read_all`, "POST", data => {
        }, JSON.stringify({
            owner_id: userData.user_id,
            model_name: sortingModel ? sortingModel : null
        }))
        setNotifications([])
        setIsNext(false)
        setNotificationCount(0)

        if (!sortingModel) {
            readAllNotificationSignal()
            readAllNavNotificationSignal()
        }
    }

    const readNotificationsResponse = async (id) => {
        try {
            const response = await fetch(`${getDomain()}/api/notifications/read`, {
                method: "POST",
                headers: {
                    // 'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    owner_id: userData.user_id,
                    notification_ids: [id],
                    instance_ids: [],
                    model_name: null
                })
            }
            )
        } catch (error) {
            console.error('Ошибка при чтении уведомления:', error);
        }
    }

    const deleteNotification = async (id, idx) => {
        setNotifications(notifications => notifications.toSpliced(idx, 1))
        setOffset(offset => offset - 1 >= 0 ? offset - 1 : 0)
        setNotificationCount(notificationsCount => notificationsCount - 1 >= 0 ? notificationsCount - 1 : 0)

        await readNotificationsResponse(id)
        readNotificationSignal(id)
        // await fetchNotificationClasses()
        await fetchNavNotifications()
    }

    const visitNotification = (model_name, obj_id, ntf_id) => {
        // if (model_name === 'TasksModel') {

        // } else {
        const obj_url = routes.filter(r => r.model_name === model_name)
        console.log(obj_url);
        console.log('obj_url', obj_url[obj_url.length - 1]);
        navigateToView(obj_url[obj_url.length - 1].name, obj_id)
        // }

    }

    const navigateToView = (name, id) => {
        // const url = `/${name}/detail/${id}`
        // // navigate(url)
        // let win = window.open(url, '_blank');

        // win.focus();

        const url = `/${name}/detail/${id}`
        let win = window.open();
        win.location = url;

    }
    const closeModal = () => {
        setActive(false)
        setTimeout(() => modal.current.style.display = 'none', 400)
    }
    const openModal = (e) => {
        e.preventDefault()
        modal.current.style.display = 'grid'
        setTimeout(() => setActive(true), 100)
    }

    return (
        <>
            <div className='notification-center_open-btn'>
                <a onClick={openModal} className="btn _square"><img src={Bell}></img>
                    <span className='notifications-number'>{notificationsCount}</span>
                </a>
            </div>
            <div className={active ? "notification-modal active" : "notification-modal"} ref={modal}
                 onClick={closeModal}>
                <div className={`notification-sidebar`} onClick={(e) => e.stopPropagation()}>
                    <div className='notification-sidebar-header'>
                        <span className='close-ntf' onClick={closeModal}></span>
                        <div className='notification-sidebar_instruments'>
                            <SelectItem
                                field={{
                                    placeholder: 'Выберите тип уведомления',
                                    children: filteredModelOptions
                                }}
                                initiator={handleModelFilterChange}
                            />
                            <div className='_read-all'>
                                <button className="btn _outline" onClick={e =>
                                    readAllNotifications()}><img src={Check} className='mr-6'></img>Прочитать все
                                </button>

                                <div className="title">
                                    <span className="title__icon d-flex p-4"><img src={WhiteBell}></img></span>
                                    <h1 className="title__text">Уведомления</h1>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='notification-sidebar-content'>
                        {notifications.map((item, idx) => {
                            return (
                                <div className='notification-item' key={idx}>
                                    <div className='d-flex w-100'
                                         onClick={e => visitNotification(item.model_name, item.instance_id, item.id)}>
                                        <img src={Message} className='notification-item-icon mr-4'></img>
                                        <div className='d-flex flex-column'>
                                            {item.message ?
                                                <p className='notification-item-text'
                                                   dangerouslySetInnerHTML={{__html: item.message}}></p>
                                                :
                                                <p className='notification-item-text'>Уведомление старого типа. Нажмите,
                                                    чтобы просмотреть.</p>
                                            }
                                            <span
                                                className='notification-item-date'>{item.upload_time.includes('+03:00') ? getUTCDate(item.upload_time, getHumanizedDateTime) : getHumanizedDateTime(item.upload_time)}</span>
                                        </div>
                                    </div>
                                    <a className='notification-item-delete'
                                       onClick={e => deleteNotification(item.id, idx)}><img src={Delete}></img></a>
                                </div>
                            )
                        }).reverse()}
                    </div>
                    <NotificationPagination
                        is_next={isNext}
                        paginate={paginate}
                        loader={loader}
                    />
                </div>
            </div>
        </>
    )
}

export default NotificationCenter