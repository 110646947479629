import React from 'react';
import "../../../styles/CardCalendar.scss"


const CardCalendar = ({event}) => {
    const date = new Date(event.start);
    const now = new Date()

    // const url = event?.url ? event.url : `/calendar/detail/${event._def.extendedProps.id}`

    const isDisabled = () => {
        if (event._def.extendedProps.end !== undefined) {
            const endTime = new Date(event._def.extendedProps.end)
            return now > endTime
        }
        return now > date
    }

    return (
        <div
            className={`calendar-item ${event.backgroundColor ? event.backgroundColor : ''} ${!event._def.extendedProps.skipDisabled && isDisabled() ? 'fc-past-time' : ''}`}>
            {event._def.extendedProps.helperText &&
                <span className={'helpText'}>{event._def.extendedProps.helperText}</span>}
            <p className='event-time'>{`${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`}</p>
            <p className='event-title'>{event.title}</p>
        </div>
    );
}

export default CardCalendar