import React from 'react'
import ViewPage from '../../components/ViewPage'
import {useSelector} from "react-redux";

const ClientTaskView = (props) => {

    const userData = useSelector(state => state.user_data.userData)
    const ReportFileButton = () => {
        return <a className={'btn'} href={`${process.env.REACT_APP_BASE_URL}/api/client_task/survey/get_report`}>Скачать
            оценку 360</a>
    }

    const isAdmin = () => {
        const groups = userData.groups ? JSON.parse(userData.groups) : []
        return groups.includes('Admin');
    }
    const hasPermissionsClientTasks360 = () => {
        const groups = userData.groups ? JSON.parse(userData.groups) : []
        return groups.includes('clientTasks360');
    }

    const getAdditionComponents = () => {
        if (!isAdmin() && !hasPermissionsClientTasks360()) return undefined

        return (
            <div className={'client-show-header-actions'}>
                <ReportFileButton/>
            </div>
        )
    }

    return (
        <ViewPage {...props} addition_components={getAdditionComponents()}/>
    )
}

export default ClientTaskView