import {getDomain} from "../../../functions/functions";

export default async function getCarList({options, token = true} = {
    options: false,
}) {
    const url = getDomain() + `/api/car/`
    const response = await fetch(url, {
        method: options ? 'OPTIONS' : 'GET',
        headers: {
            ...(token ? {'Authorization': `Bearer ${localStorage.getItem("token")}`}: {}),
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })
    if (response.ok) {
        return response.json()
    }
    return null
}