import React from "react"
import "../../../styles/CarBooking/CarBookingCalendarTable.scss"
import {useNavigate} from 'react-router-dom';
import BusinessTrip from "/public/interface_img/business-trip.svg"
import Maintenance from "/public/interface_img/maintenance.svg"
import Washing from "/public/interface_img/washing.svg"


const CalendarTable = ({data, dates}) => {
    const navigate = useNavigate();

    const hours = Array.from({length: (18 - 9 + 1) * 2}, (_, i) => {
        if (i % 2 === 0) {
            return `${Math.floor(i / 2) + 9}:00`;
        } else {
            return ` `;
        }
    });

    const convertDate = (dateStr) => {
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        const [day, month, year] = dateStr.split(' ');

        const monthIndex = months.indexOf(month) + 1;
        return `${day.padStart(2, '0')}.${monthIndex.toString().padStart(2, '0')}.${year}`;
    };

    let rowsOccupied = [];

    const clearRowOccupied = () => {
        rowsOccupied = [];
    }

    function placeItemInRow(gridColStart, gridColEnd) {
        for (let i = 0; i < rowsOccupied.length; i++) {

            const conflict = rowsOccupied[i].some(interval => {
                return !(gridColEnd <= interval.start || gridColStart >= interval.end);
            });
            if (!conflict) {
                rowsOccupied[i].push({start: gridColStart, end: gridColEnd});
                return i + 1;
            }
        }

        rowsOccupied.push([{start: gridColStart, end: gridColEnd}]);
        return rowsOccupied.length;
    }

    const tableItem = (item, index) => {

        const timeToIndex = (time) => {
            const [hour, minute] = time.split(':').map(Number);
            return (hour - 9) * 2 + (minute === 30 ? 1 : 0) + 1;
        };

        const getTripTypeImg = (type) => {
            switch (type) {
                case 'Служебная поездка':
                    return BusinessTrip
                case 'Обслуживание':
                    return Maintenance
                case 'Мойка':
                    return Washing
            }
            return BusinessTrip
        }

        const startTime = item.booking_time.slice(-5);
        const endTime = item.end_booking_time.slice(-5);


        const gridColStart = timeToIndex(startTime);
        const gridColEnd = timeToIndex(endTime);

        const gridRowStart = placeItemInRow(gridColStart, gridColEnd);

        return (
            <div key={index} className="calendar-table-row-item" style={{
                backgroundColor: item.car_card_color,
                gridColumnStart: gridColStart,
                gridColumnEnd: gridColEnd,
                gridRowStart: gridRowStart,
                gridRowEnd: gridRowStart + 1,
                cursor: "pointer",
            }}
                 onClick={() => navigate(`detail/${item.id}`)}>
                <p>{item.responsible_name}</p>
                <div>
                    <div className="calendar-table-row-item-image-address">
                        <img className="calendar-table-row-item-image" src={getTripTypeImg(item.trip_type)}
                             alt={item.tire_type}/>
                        <p>{item.end_address}</p>
                    </div>
                    <p className="calendar-table-row-item-car-name">{item.car_name}</p>
                    <p className="calendar-table-row-item-car-number">{item.car_number}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="calendar-tables-container">
            {dates.map((date, i) => (
                <div key={i} className="calendar-table-container">
                    {clearRowOccupied()}
                    {i !== 0 &&
                        <div className="calendar-table-date-container">
                            <div className="calendar-table-date">{date}</div>
                        </div>
                    }

                    <div className="calendar-table-header">
                        {hours.map((hour, idx) => (
                            <p key={idx} className="calendar-table-header-item">{hour}</p>
                        ))}
                    </div>

                    <div className="calendar-table-grid">
                        {data.filter(item =>
                            item.booking_time.startsWith(convertDate(dates[i]))).map((item, index) => (
                            tableItem(item, index)
                        ))}
                    </div>

                </div>
            ))
            }
        </div>
    )
}

export default CalendarTable;