import React, {useEffect, useState} from "react";
import Alert from "../../components/Allert";
import FormItem from "../../components/form/FormItem";
import {DateTimeField, TimeSwitcher} from "../CalendarBooking/components";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCalendarBookingDetail, patchCalendarBooking} from "../CalendarBooking/api";
import HeaderInside from "../../components/HeaderInside";
import FormErrorsContext from "../../functions/formErrorsContext";
import AddedFormItem from "../../components/form/AddedFormItem";
import {getBookingCarDetail, patchBookingCar} from "./api";
import ContentLoader from "../../components/ContentLoader";

const CarBookingEdit = () => {
    const params = useParams()
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const [formErrors, setFormErrors] = useState()
    const navigate = useNavigate();

    const duration = [
        {value: '1', display_name: '1', label: '1 час'},
        {value: '2', display_name: '2', label: '2 часа'},
        {value: '3', display_name: '3', label: '3 часа'},
        {value: '4', display_name: '4', label: '4 часа'},
        {value: '5', display_name: '5', label: '5 часов'},
        {value: '6', display_name: '6', label: '6 часов'},
        {value: '7', display_name: '7', label: '7 часов'},
        {value: '8', display_name: '8', label: '8 часов'},
        {value: '9', display_name: '9', label: '9 часов'},
    ]

    const booking_types = [{value: 'Служебная поездка', display_name: 'Служебная поездка', label: 'Служебная поездка'},
        {value: 'Обслуживание', display_name: 'Обслуживание', label: 'Обслуживание'},
        {value: 'Мойка', display_name: 'Мойка', label: 'Мойка'}]

    useEffect(() => {
        const getFields = async () => {
            if (params.id) {
                const data = await getBookingCarDetail(params.id)
                const options = (await getBookingCarDetail(params.id, true))?.actions?.PUT

                const newFields = {}
                const keys = Object.keys(data || {})
                keys.forEach((key) => {
                    newFields[key] = {...options[key], value: data[key]}
                })
                setFields(newFields)
                console.log(newFields)
            }
        }

        getFields()
    }, [params.id])

    const isAuthor = () => {
        return (+fields?.responsible?.value === +userData.user_id)
    }

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1;
    }

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    const convertMembersValue = (value) => {
        return value.map(item => ({value: item}))
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const obj = {...Object.fromEntries(formData.entries())}

        let time = parseInt(obj.booking_time.slice(-5).slice(0, 2))
        const minute = obj.booking_time.slice(-2) === '30' ? 0.5 : 0
        time = time + minute

        if (time >= 9 && time <= 18 && (time + parseInt(obj.booking_duration) <= 18)) {
            const response = await patchBookingCar(params.id, obj)

            if (response?.ok) {
                setAlert({type: 'success', title: 'Успешно'})
            } else {
                if (response?.error[0] === "Время бронирования пересекается с другими бронированиями.") {
                    setAlert({
                        type: 'error',
                        title: 'Не удалось забронировать поездку',
                        text: 'На данное время уже нет свободных автомобилей'
                    })
                } else {
                    setAlert({type: 'error', title: 'Ошибка', text: response?.error})
                }
            }
        } else {
            setAlert({
                type: 'error',
                title: 'Дата и время начала поездки',
                text: "Рабочее время с 9:00 до 18:00"
            })
        }
    }

    const getTimeRu = (time) => {
        duration.map((item) => {
            if (parseInt(item.value) === time) {
                return item.label
            }
        })
        return '1 час'
    }

    if (!fields)
        return <ContentLoader/>

    return (
        <div className={"page"}>
            <>
                <HeaderInside title={'Бронирование автомобиля'}/>
                <hr/>
                <div className="page__content">
                    <form className={"form-container"} onSubmit={onSubmit}>
                        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>

                            {fields?.car &&
                                <FormItem field={{
                                    ...fields.car,
                                    children: convertChoices(fields.car.choices),
                                    type: 'select',
                                    placeholder: '',
                                    name: 'car'
                                }} classes="_full"/>}
                            {fields?.booking_time &&
                                <DateTimeField startValue={fields.booking_time.value} name={'booking_time'}
                                               label={'Дата и время начала поездки'} required={true}/>}
                            {fields?.trip_type &&
                                <FormItem field={{
                                    ...fields.trip_type,
                                    children: booking_types,
                                    type: "select",
                                    placeholder: '',
                                    name: 'trip_type'
                                }}/>}
                            {fields?.booking_duration &&
                                <FormItem field={{
                                    ...fields.booking_duration,
                                    children: duration,
                                    type: "select",
                                    name: 'booking_duration',
                                    value: fields.booking_duration.value.toString(),
                                }}/>}
                            {fields?.start_address && <FormItem field={{
                                ...fields.start_address,
                                type: 'text',
                                placeholder: '',
                                name: 'start_address',
                            }} classes="_full"/>}
                            {fields?.end_address && <FormItem field={{
                                ...fields.end_address,
                                type: 'text',
                                placeholder: '',
                                name: 'end_address',
                            }} classes="_full"/>}
                            {fields?.responsible &&
                                <FormItem field={{
                                    ...fields.responsible,
                                    children: convertChoices(fields.responsible.choices),
                                    type: 'select',
                                    placeholder: '',
                                    name: 'responsible',
                                }} classes="_full"/>}
                            {fields?.note && <FormItem field={{
                                ...fields.note,
                                type: 'textarea',
                                name: 'note',
                                placeholder: '',
                            }} classes="_full"/>}

                            {(isAuthor() || isAdmin()) && <div className="form__buttons-panel">
                                {/*<a  onClick={e => navigateToView(1)}>ложное сохранение</a>*/}
                                <input type="submit" id="save_button" className="d-none" value="Send"/>
                                <label htmlFor="save_button"
                                       className={"save_button_fixed"}>{'Сохранить'}</label>
                            </div>}
                        </FormErrorsContext.Provider>
                    </form>
                    {alert && <Alert setAlert={setAlert} alert={alert}/>}
                </div>
            </>
        </div>
    )
}

export default CarBookingEdit;