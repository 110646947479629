import React from "react"
import {useNavigate, Link, useParams, useLocation} from "react-router-dom"

function HeaderInside({title = 'Пустая страница', icon = '__to-back', edit = false , create = false , delete_btn = false , clickEvent = false, delete_btn_title = false}) {
    const navigate = useNavigate(),
        goBack = () => navigate(-1),
        params = useParams()

    const location = useLocation()

    const onClick = (e) => {
        const queryParams = new URLSearchParams(location.search);
        const callbackUrl = queryParams.get('callbackUrl')

        if (callbackUrl) {
            navigate(callbackUrl)
        } else if (clickEvent) {
            clickEvent(e)
        } else {
            goBack(e)
        }
    }

    return (
        <div className="header">
            <div className={`title ${icon}`}>
                <a onClick={onClick} className={`title__icon ${icon}`}></a>
                <h1 className="title__text">{title}</h1>
            </div>
            <div></div>
            <div className="control">
                {edit ? <Link to={edit.url ? edit.url : 'edit/' + params.id} className="btn">{edit.title ? edit.title : 'Редактировать'}</Link> : ''}
                {create && create.title ? <Link to={create.url ? create.url : 'create/' + params.id} className="btn _cyan">{create.title ? create.title : 'Создать ещё'}</Link> : ''}
                {delete_btn ? <a onClick={delete_btn.deleteItem} className="btn _red">{delete_btn_title ? delete_btn_title : 'Удалить'}</a> : ''}
            </div>
        </div>
    )
}

export default HeaderInside