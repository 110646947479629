import {getDomain} from "../../../functions/functions";

export default async function getCarDetail(id, options = false) {
    const url = getDomain() + `/api/car/${id}`
    const response = await fetch(url, {
        method: options ? 'OPTIONS' : 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })

    const body = await response.json()

    if (response.ok) {
        return body
    }
    return null
}