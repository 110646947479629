import {getDomain} from "../../../functions/functions";

export default async function getBookingCarList({options, date_before, date_after, token = true} = {
    options: false,
    date_after: '',
    date_before: ''
}) {
    const url = getDomain() + `/api/booking/car/?date_after=${date_after || ''}&date_before=${date_before || ''}`
    const response = await fetch(url, {
        method: options ? 'OPTIONS' : 'GET',
        headers: {
            ...(token ? {'Authorization': `Bearer ${localStorage.getItem("token")}`}: {}),
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })
    if (response.ok) {
        return response.json()
    }
    return null
}