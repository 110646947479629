import React, {useEffect, useState} from "react";
import {
    createFieldsObject,
    getDomain,
    getItems,
} from "../../functions/functions";
import DashboardCard from "./DashboardCard";

import minimize from "../../../public/interface_img/minimize.svg";

//                         icons
import user from "../../../public/page_icons/users.svg";
import tasks from "../../../public/page_icons/tasks.svg";
import client_task from "../../../public/page_icons/client-task.svg";
import incoming from "../../../public/page_icons/incoming.svg";
import output from "../../../public/page_icons/outgoing.svg";
import service_records from "../../../public/page_icons/service_records.svg";
import microdistrict from "../../../public/page_icons/microdistrict.svg";
import trend_selling from "../../../public/page_icons/trend_selling.svg";
import control_building from "../../../public/page_icons/control_building.svg";
import contragent from "../../../public/page_icons/counterparty.svg";
import agreement from "../../../public/page_icons/ahosupport.svg";
import doc_reestr from "../../../public/page_icons/register.svg";
import digital_archive from "../../../public/page_icons/digital-archive.svg";
import aho_support from "../../../public/page_icons/ahosupport.svg";
import tech_support from "../../../public/page_icons/tech_support.svg";
import exploitation_support from "../../../public/page_icons/exsupport.svg";
import cap_support from "../../../public/page_icons/capsupport.svg";
import {useNavigate} from "react-router-dom";

const DashboardFullScreen = () => {
    const icons = {
        user: user,
        tasks: tasks,
        client_task: client_task,
        incoming: incoming,
        output: output,
        service_records: service_records,
        microdistrict: microdistrict,
        trend_selling: trend_selling,
        control_building: control_building,
        contragent: contragent,
        agreement: agreement,
        doc_reestr: doc_reestr,
        digital_archive: digital_archive,
        aho_support: aho_support,
        tech_support: tech_support,
        exploitation_support: exploitation_support,
        cap_support: cap_support
    };
    const navigate = useNavigate();

    const sections = [
            {
                title: "Задачи и документооборот",
                items: [
                    "user",
                    "tasks",
                    "client_task",
                    "incoming",
                    "output",
                    "service_records",
                ],
                double: true,
            },
            {
                title: "Земельные участки и строительство",
                items: ["microdistrict", "trend_selling", "control_building"],
                double: true,
            },
            {
                title: "Договорная работа",
                items: ["contragent", "agreement", "doc_reestr", "digital_archive"],
                double: false,
            },
            {
                title: "Заявки отделов",
                items: [
                    "aho_support",
                    "tech_support",
                    "exploitation_support",
                    "cap_support",
                ],
                double: false,
            },
        ],
        [fields, setFields] = useState({}),
        [values, setValues] = useState({});

    useEffect(() => {
        getItems(`${getDomain()}/api/dashboard/`, "OPTIONS", setData);
        let timer = setInterval(
            () => getItems(`${getDomain()}/api/dashboard/`, "OPTIONS", setData),
            60000
        );

        // getItems(`${props.url}`, 'GET', setData)
    }, []);

    const setRecursiveValue = (options, data) => {
        for (const key in data) {
            if (typeof data[key] === "object") {
                setRecursiveValue(options[key], data[key]);
            } else {
                options[key].value = data[key];
            }
        }
    };

    const setData = (options) => {
        let newFields = options;
        const setValues = (data) => {
            setRecursiveValue(newFields, data);

            setFields({...newFields});
        };

        getItems(`${getDomain()}/api/dashboard/`, "GET", setValues);
    };

    return (
        <div className="dashboard-full-page page">
            <div className="dashboard">
                {sections.map((section, idx) => {
                    return (
                        <div className="dashboard-section" key={idx}>
                            <p className="dashboard-section_title">{section.title}</p>
                            <div
                                className={`dashboard_cards-block ${
                                    section.double ? "double-items" : ""
                                }`}
                            >
                                {section.items.map((item, idx) => {
                                    return (
                                        <DashboardCard
                                            key={idx}
                                            name={item}
                                            options={fields[item]}
                                            data={values[item]}
                                            img={icons[item]}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <a
                className="btn _outline minimize-btn"
                onClick={() => navigate("/dashboard")}
            >
                <img src={minimize} alt=""/>
            </a>
        </div>
    );
};

export default DashboardFullScreen;
