import React from "react";
import {useState} from "react";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import '../../../styles/CarBooking/CarBookingPagination.scss'
import ChevronLeft from "/public/interface_img/chevron-left.svg";
import ChevronRight from "/public/interface_img/chevron-right.svg";

const Pagination = ({onDateChange}) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const DAYS_TO_VIEW = 7;

    const handlePrev = () => {
        const prevDate = new Date(currentDate);
        prevDate.setDate(prevDate.getDate() - DAYS_TO_VIEW);
        setCurrentDate(prevDate);
        onDateChange(prevDate);
    }

    const handleNext = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(nextDate.getDate() + DAYS_TO_VIEW);
        setCurrentDate(nextDate);
        onDateChange(nextDate);
    }

    const prevButton = () => {
        return (
            <div className="car-booking-pagination-button" onClick={handlePrev}>
                <img src={ChevronLeft} alt="prev days" />
            </div>
        )
    }

    const nextButton = () => {
        return (
            <div className="car-booking-pagination-button" onClick={handleNext}>
                <img src={ChevronRight} alt="nexts days" />
            </div>
        )
    }


    return (
        <div className="car-booking-pagination">
            {prevButton()}

            <div className="car-booking-pagination-date">
                {format(currentDate, "dd MMMM yyyy 'г.'", { locale: ru })}
            </div>

            {nextButton()}
        </div>
    )
}

export default Pagination;