import {getDomain} from "../../../functions/functions";

export default async function postAutoBookingCar(data) {
    const url = getDomain() + `/api/booking/car/auto_booking/`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(data)
    })
    return {ok: !!response.ok, error: (await response.json())?.error}
}