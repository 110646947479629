import React, {useEffect, useState} from "react"
import Alert from "../../components/Allert";
import {InfoItem} from "../../components/InfoItem";
import HeaderInside from "../../components/HeaderInside";
import AddedFormItem from "../../components/form/AddedFormItem";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {deleteCalendarBooking, getCalendarBookingDetail, patchCalendarBooking} from "../CalendarBooking/api";
import {deleteBookingCar, getBookingCarDetail, patchBookingCar} from "./api";
import {getCurrentDateTime} from "../../functions/getCurrentDateTime";
import {isCurrentDateTime} from "../../functions/isCurrentDateTime";
import endBookingCar from "./api/endBookingCar";
import ContentLoader from "../../components/ContentLoader";

const CarBookingDetail = (props) => {
    const params = useParams()
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            if (params.id) {
                const data = await getBookingCarDetail(params.id)
                const options = (await getBookingCarDetail(params.id, true))?.actions?.PUT

                const newFields = {}
                const keys = Object.keys(data || {})
                keys.forEach((key) => {
                    newFields[key] = {...options[key], value: data[key]}
                })
                setFields(newFields)
            }
        }

        getFields()
    }, [params.id])

    const isAdmin = () => {
        const groups = userData.groups ? JSON.parse(userData.groups) : []
        return groups.includes('Admin');
    }

    const isAuthor = () => {
        return (+fields?.responsible?.value === +userData.user_id)
    }

    const isDriver = () => {
        return (+fields?.driver?.value === +userData.user_id)
    }

    const isPrevDays = () => {
        return fields?.end_booking_time && (parseDate(getCurrentDateTime()) > parseDate(fields.end_booking_time.value))
    }

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    const convertMembersValue = (value) => {
        return value.map(item => ({value: item}))
    }

    const convertValueToArray = (value) => {
        if (Array.isArray(value)) {
            return value
        }
        return [value]
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const response = await patchBookingCar(params.id, {participants: formData.getAll('participants')})
        if (response) {
            setAlert({type: 'success', title: 'Успешно', text: ''})
        }
    }

    const deleteItem = async () => {
        if (params.id) {
            const response = await deleteBookingCar(params.id);
            if (response)
                navigate('/car-booking')
        }
    }

    function parseDate(dateString) {
        const [day, month, year, hour, minute] = dateString.split(/[\s.:]+/);
        return new Date(year, month - 1, day, hour, minute); // month - 1, так как месяцы в Date начинаются с 0
    }

    const endBooking = async () => {
        if (params.id) {
            if (parseDate(getCurrentDateTime()) > parseDate(fields.booking_time.value)) {
                const response = await endBookingCar(params.id)
                if (response) {
                    setAlert({
                        type: 'success',
                        title: 'Успешно',
                        text: ''
                    })
                    navigate('/car-booking')
                }
            } else
                setAlert({
                    type: 'error',
                    title: 'Не удалось отменить поездку',
                    text: 'Поездка ещё не началась'
                })
        } else
            setAlert({
                type: 'error',
                title: 'Не удалось отменить поездку',
                text: 'Ошибка сервера, попробуйте позднее'
            })
    }

    const duration = [
        {value: '1', display_name: '1', label: '1 час'},
        {value: '2', display_name: '2', label: '2 часа'},
        {value: '3', display_name: '3', label: '3 часа'},
        {value: '4', display_name: '4', label: '4 часа'},
        {value: '5', display_name: '5', label: '5 часов'},
        {value: '6', display_name: '6', label: '6 часов'},
        {value: '7', display_name: '7', label: '7 часов'},
        {value: '8', display_name: '8', label: '8 часов'},
        {value: '9', display_name: '9', label: '9 часов'},
    ]

    function getDisplayNameByValue(value) {
        const item = duration.find(entry => entry.value === String(value));
        return item ? item.label : null;
    }

    function differenceTime(start, end) {
        const [hours1, minutes1] = String(start).split(':').map(Number);
        const [hours2, minutes2] = String(end).split(':').map(Number);

        return [hours2 - hours1, minutes2 - minutes1];
    }

    function getBookingDuration() {
        const startTime = fields?.booking_time.value.slice(-5)
        const endTime = fields?.end_booking_time.value.slice(-5)

        const bookingTime = differenceTime(startTime, endTime);

        const item = duration.find(entry => entry.value === String(bookingTime[0]));

        return (item?.label || '') + (bookingTime[1] === 0 ? '' : ' 30 минут')
    }

    console.log(fields)

    if (!fields)
        return <ContentLoader/>
    else
        return (
            <div className={"page"}>
                <>
                    <HeaderInside title={'Бронирование автомобиля'}
                                  delete_btn_title={'Отменить бронирование'}
                                  edit={!isPrevDays() && (isAdmin() || isAuthor()) ? {url: `/car-booking/edit/${params.id}`} : false}
                                  delete_btn={!isPrevDays() && (isAdmin() || isAuthor()) ? {deleteItem: deleteItem} : false
                                  }
                    />
                    <hr/>
                    <div className="page__content">
                        {alert && <Alert setAlert={setAlert} alert={alert}/>}
                        <div className={"page__info-container"}>
                            {fields.car && <InfoItem item={{
                                ...fields.car,
                                type: 'nested',
                                choices: convertChoices(fields.car.choices),
                                value: convertValueToArray(fields.car.value)
                            }} classes="_full"/>}
                            {fields.booking_time &&
                                <InfoItem item={{...fields.booking_time, label: "Дата и время начала поездки"}}
                                          classes="_full"/>}
                            {fields.trip_type && <InfoItem item={fields.trip_type}/>}
                            {fields.booking_duration && <InfoItem item={{
                                ...fields.booking_duration,
                                value: getBookingDuration()
                            }}/>}
                            {fields.start_address && <InfoItem item={fields.start_address} classes="_full"/>}
                            {fields.end_address && <InfoItem item={fields.end_address} classes="_full"/>}
                            {fields.responsible && <InfoItem item={{
                                ...fields.responsible,
                                type: 'nested',
                                choices: convertChoices(fields.responsible.choices),
                                value: convertValueToArray(fields.responsible.value)
                            }} classes="_full"/>}
                            {fields.note &&
                                <InfoItem item={{...fields.note, value: fields.note.value || ''}} classes="_full"/>}

                            {!isPrevDays() && fields && isDriver() && isCurrentDateTime(fields.booking_time.value) ?
                                <a onClick={endBooking} className="btn _red">{'Завершить бронирование'}</a> : ''}
                        </div>
                    </div>
                </>
            </div>
        )
}

export default CarBookingDetail;

