import React, {useEffect, useState} from "react";
import AddCalendarBookingButton from "../CalendarBooking/components/AddCalendarBookingButton";
import EventCalendarTable from "../EventCalendar/EventCalendarTable";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import ViewPage from "../../components/ViewPage";
import {getDomain} from "../../functions/functions";
import {checkBookingCarExelFileRequest} from "./api";
import Alert from "../../components/Allert";
import {Link} from "react-router-dom";
import ContentLoader from "../../components/ContentLoader";
import {useSelector} from "react-redux";

const CarBookingView = (props) => {
    const [alert, setAlert] = useState(false);
    const userData = useSelector(state => state.user_data.userData)

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1;
    }

    const isDispatcher = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Dispatcher') !== -1;
    }

    const DownloadBookingCarFile = () => {
        const onClick = async () => {
            const response = await checkBookingCarExelFileRequest()

            if (response) {
                const url = `${getDomain()}/api/booking/car/generate_excel`
                const a = document.createElement('a');
                a.href = url;
                a.click();
            } else {
                setAlert({type: 'error', title: 'Ошибка', text: 'Бронирования автомобилей отсутствуют'})
            }
        }

        return (
            <div className={'client-show-header-actions-button'}>
                <button onClick={onClick}
                        className={'btn'}>{"Скачать график бронирования"}</button>
            </div>
        )
    }

    const DispatcherEdit = () => {
        if (isAdmin() || isDispatcher())
            return (
                <div className={'client-show-header-actions-button'}>
                    <Link to={`/car-booking/dispatcher/`} className="btn">{'Диспетчер'}</Link>
                </div>
            )
    }

    const Taxi = () => {
        return (
            <div className={'client-show-header-actions-button car-booking-taxi'}>
                <Link to={`/car-booking/taxi/`} className="btn">{'Такси'}</Link>
            </div>
        )
    }

    if (!props)
        return <ContentLoader/>

    return (
        <>
            <ViewPage {...props} addition_components={(
                <div className={'car-booking-calendar-actions'}>
                    <Taxi/>
                    <DownloadBookingCarFile/>
                    <DispatcherEdit/>
                </div>
            )}/>
            <Alert setAlert={setAlert} alert={alert}/>
        </>
    )
}

export default CarBookingView;