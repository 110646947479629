import React, {useEffect, useRef, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list'; // Подключение плагина для списка
import CardCalendar from './CardCalendar/CardCalendar';
import {useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';
import Alert from '../../components/Allert'
import {formatDate} from '@fullcalendar/core';
import ContentLoader from "../../components/ContentLoader";
import {useSelector} from 'react-redux';
import {getItems} from '../../functions/functions';
import getCalendarBookingList from "../CalendarBooking/api/getCalendarBookingList";


const EventCalendarTable = (props) => {
    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();
    const [isErr, setIsErr] = useState(false);
    const [currentView, setCurrentView] = useState('dayGridMonth')
    const [selectedDate, setSelectedDate] = useState(null);
    const [statikEvent, setStatikEvent] = useState([]);
    const calendarRef = useRef(null);
    const [loading, setLoading] = useState(() => <ContentLoader/>)
    const [items, setItems] = useState([])
    const userData = useSelector(state => state.user_data.userData)
    const location = useLocation();
    const [alert, setAlert] = useState({
        title: '',
        text: '',
        type: '', // error, alert, info, success
    })

    const [calendarBookingEvents, setCalendarBookingEvents] = useState([])

    const eventColor = {
        "Мероприятие": "_blue-event",
        "Встреча": "_green-event",
        "Совещание": "_yellow-event",
        "Совещание с участием гендиректора": "_red-event",
        "Мероприятие с участием гендиректора": "_red-event",
    }


    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth,
                });
            };

            handleResize(); // Вызвать функцию сразу, чтобы получить текущие размеры
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowSize;
    };

    const {width} = useWindowSize();

    useEffect(() => {
        if (width <= 1024) {
            calendarRef?.current?.getApi().changeView('listDay');
        } else {
            calendarRef?.current?.getApi().changeView('dayGridMonth');
        }

    }, [width]);

    useEffect(() => {
        setAlert({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })

        const getCalendarBooking = async (dateStart, dateEnd) => {
            const response = await getCalendarBookingList({date_after: dateStart, date_before: dateEnd})
            const events = response.results
            setCalendarBookingEvents(events)
            return events.length
        }

        if (dateStart && dateEnd) {
            getItems(`${props.url}&limit=1000${props.is_my ? '&is_my=' + userData.user_id : ''}${dateStart ? '&date_after=' + dateStart : ''}${dateEnd ? '&date_before=' + dateEnd : ''}`,
                "GET",
                async (data) => {
                    setItems([...data.results])
                    const bookingEventsLength = await getCalendarBooking(dateStart, dateEnd)

                    // если событий нет показываем алерт
                    if (data.results?.length === 0 && bookingEventsLength === 0) {
                        setAlert({
                            title: 'Календарь пуст',
                            text: `${calendarRef?.current?.getApi().view.type === 'dayGridMonth' ? 'В этом месяце пока не запланировано событий' : 'В этот день пока не запланировано событий'}`,
                            type: 'info', // error, alert, info, success
                        })
                    }
                },
                false,
                (error) => {
                    setAlert({
                        title: 'Ошибка загрузки',
                        text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                        type: 'error', // error, alert, info, success
                    })
                })
        }
    }, [dateStart, dateEnd])

    const handleSelect = (arg) => {
        setSelectedDate(arg.startStr);
        setIsErr(false);
    };

    const handleDatesSet = (dateInfo) => {
        const startOfMonth = dateInfo.view.activeStart; // Дата начала показываемого месяца
        const endOfMonth = dateInfo.view.activeEnd;

        const day = startOfMonth.getDate();
        const month = startOfMonth.getMonth() + 1; // Месяцы в объекте Date нумеруются с 0, поэтому добавляем 1
        const year = startOfMonth.getFullYear();
        const dayEnd = endOfMonth.getDate();
        const monthEnd = endOfMonth.getMonth() + 1;
        const yearEnd = endOfMonth.getFullYear();

        const formattedDateStart = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
        const formattedDateEnd = `${dayEnd < 10 ? '0' : ''}${dayEnd}.${monthEnd < 10 ? '0' : ''}${monthEnd}.${yearEnd}`;

        setDateStart(formattedDateStart) // Дата начала показываемого месяца=- 
        setDateEnd(formattedDateEnd) // Дата конца показываемого месяца
    };

    function isSameDay(date1, date2) {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    }

    const navigate = useNavigate();
    const handleCreateEvent = (date) => {
        navigate('create', {state: {selectedDate: selectedDate}})
    };

    const renderPressITem = () => {
        const events = items?.map((item) => {
            const startDate = moment(item.start_date, 'DD.MM.YYYY');
            const startTime = moment(item.start_time, 'HH:mm:ss');
            const startDateTime = moment(startDate).set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second(),
            });

            const color = eventColor[item.meeting_type]
            return {
                title: item.name,
                start: startDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                end: startDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                backgroundColor: color,
                url: `/${String(location.pathname || '').replace(/\//g, '')}/detail/${item.id}`,
                extendedProps: {
                    id: item.id,
                    details: item.description,
                },
            };
        });

        const bookingEvents = calendarBookingEvents.map(item => {
            const isTakePart = [...(item?.participants || []), item.responsible_id].find(idItem => idItem === Number(userData.user_id)) !== undefined
            const startDateTime = moment(item.booking_time, 'DD.MM.YYYY HH:mm');
            const endateTime = moment(item.end_booking_time, 'DD.MM.YYYY HH:mm');
            return {
                title: item.title,
                start: startDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                ...(isTakePart || !props.my ? {url: `/calendar-booking/detail/${item.id}`} : {}),
                backgroundColor: isTakePart || !props.my ? '_orange-event' : '_not_take_part',
                extendedProps: {
                    helperText: item.meeting_room,
                    end: endateTime.format('YYYY-MM-DDTHH:mm:ss'),
                    id: item.id,
                    skipDisabled: !(isTakePart || !props.my),
                },
            }
        })
        setStatikEvent([...events, ...bookingEvents]);
        setLoading(false)
    }

    useEffect(() => {
        renderPressITem()
    }, [dateStart, items, calendarBookingEvents]);


    const [hoveredDate, setHoveredDate] = useState(false);

    const renderDayCellContent = (arg) => {
        const today = new Date();
        const day = arg.date.getDate().toString();
        const month = arg.date.toLocaleString('ru', {month: 'short'});

        //подсчет количества событий в день
        const eventsOnThisDay = statikEvent.filter(event =>
            formatDate(event.start, {year: 'numeric', month: '2-digit', day: '2-digit'}) ===
            formatDate(arg.date, {year: 'numeric', month: '2-digit', day: '2-digit'})
        );

        arg.dayNumberText = `${eventsOnThisDay.length}`;

        return (
            <div
                className={`custom-date-card ${hoveredDate ? 'hovered' : ''}`}
            >
                {`${day} ${month}`}


                {(arg.date === hoveredDate && hoveredDate >= today.setDate(today.getDate() - 1)) ?
                    <button onClick={() => handleCreateEvent(hoveredDate)} className='buttonAddPost'>Добавить</button>
                    :
                    <>
                        {arg.dayNumberText > 0 &&
                            <p>Событий: {arg.dayNumberText}</p>
                        }
                    </>
                }
            </div>
        );
    };

    return (
        <div className="Calendar">
            {alert.title && <Alert setAlert={setAlert} alert={alert}/>}
            {loading ?
                <div className="table-wrap">
                    <div>{loading}</div>
                </div>
                :
                <div className="CalendarContainer">
                    <FullCalendar
                        datesSet={(dateInfo) => handleDatesSet(dateInfo)}
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,title,next',
                            center: '',
                            right: '',
                        }}
                        locale="ru"
                        noEventsText='В этот день нет запланированных событий' height="auto"
                        eventContent={CardCalendar}
                        dayCellContent={renderDayCellContent}
                        dayCellDidMount={(cellInfo) => {
                            const cellEl = cellInfo.el;
                            const date = cellInfo.date; // Получаем дату из cellInfo
                            cellEl.addEventListener('mouseover', () => setHoveredDate(date)); // Используем замыкание для передачи даты в обработчик
                            cellEl.addEventListener('mouseleave', () => setHoveredDate(null));
                        }}
                        editable={true}
                        eventStartEditable={false}
                        eventDurationEditable={false}
                        selectable={true}
                        select={(e) => handleSelect(e)} // Обработчик события выбора
                        firstDay={1}
                        dayCellClassNames={({date, view}) => {
                            const today = new Date();
                            const isBeforeToday = date < today && !isSameDay(date, today);
                            const isAfterToday = date > today && !isSameDay(date, today);
                            return isBeforeToday ? 'before-today' : isAfterToday ? 'after-today' : '';
                        }}
                        customButtons={{
                            buttonAddEvent: {
                                text: 'Создать',
                                click: handleCreateEvent,
                            },
                        }}
                        events={statikEvent}
                        initialView={currentView}
                        views={{
                            listDay: {
                                type: 'list',
                                duration: {days: 1},
                                buttonText: 'День',
                            },
                            dayGridMonth: {
                                type: 'dayGrid',
                                duration: {weeks: 5},
                                buttonText: 'Неделя',
                                weekNumbers: false, // Включение отображения номеров недель
                                dayHeaderFormat: {weekday: 'short'},
                            },
                        }}
                        fixedWeekCount={false}
                    />
                </div>
            }
        </div>
    )
}

export default EventCalendarTable