import HeaderInside from "../../components/HeaderInside";
import FormErrorsContext from "../../functions/formErrorsContext";
import FormItem from "../../components/form/FormItem";
import Alert from "../../components/Allert";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import getCarList from "./api/getCarList";
import "../../styles/CarBooking/Car/Car.scss"
import getCarDetail from "./api/getCarDetail";
import ContentLoader from "../../components/ContentLoader";
import patchCar from "./api/patchCar";

export default function CarEdit() {
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const [cars, setCars] = useState()
    const [formErrors, setFormErrors] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            const data = await getCarList()

            const fieldsArray = []

            // const allOptions = (await getCarList({options: true, token: true}))?.actions?.PUT
            // console.log(allOptions)
            // console.log(data)

            if (data.results && data.results.length > 0) {
                const options = (await getCarDetail(data.results[0].id, true))?.actions?.PUT
                for (const car of data.results) {

                    const keys = Object.keys(car || {})
                    const newField = {}

                    keys.forEach((key) => {
                        newField[key] = {
                            ...options[key], value: car[key]
                        }
                    })

                    fieldsArray.push({
                        ...newField,
                        value: car.id
                    })
                }
            }
            setFields(fieldsArray)
            setCars(data.results)
        }
        getFields()
    }, [])

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1;
    }

    const isDispatcher = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Dispatcher') !== -1;
    }

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    function findNonMatchingObjects(newArray, oldArray) {
        return newArray.filter(newItem => {
            const oldItem = oldArray.find(old =>
                String(old.id) === String(newItem.id)
            );

            if (!oldItem) return true;

            const isActiveMatch = oldItem.is_active.toString() === newItem.is_active;
            const isTaxiMatch = oldItem.is_taxi.toString() === newItem.is_taxi

            const nameMatch = oldItem.name && (oldItem.name === newItem.name);
            const carNumberMatch = oldItem.car_number && (oldItem.car_number === newItem.car_number);
            const driverMatch = oldItem.driver && (oldItem.driver.toString() === newItem.driver);

            return !(isActiveMatch && nameMatch && carNumberMatch && driverMatch && isTaxiMatch);
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)

        // Собираем данные в массив объектов
        const data = [];
        formData.forEach((value, key) => {
            const match = key.match(/^cars\[(\d+)\]\.(\w+)$/);
            if (match) {
                const id = match[1]; // field.value
                const prop = match[2];

                if (!data[id]) {
                    data[id] = {id}; // Добавляем id в объект
                }

                data[id][prop] = value;
            }
        });

        const result = Object.values(data).filter(item =>
            Object.keys(item).length > 1 || item.id !== undefined
        );

        const patchData = findNonMatchingObjects(result, cars)
        for (const patch of patchData) {
            const {id, ...carWithoutId} = patch;

            const response = await patchCar(id, carWithoutId);

            if (response?.ok) {
                setAlert({type: 'success', title: 'Успешно'})
            } else {
                setAlert({type: 'error', title: 'Ошибка', text: response?.error})
            }
        }
        navigate(0)
    }

    const convertValueToArray = (value) => {
        if (Array.isArray(value)) {
            return value
        }
        return [value]
    }

    const isActive = [
        {value: 'true', display_name: 'true', label: 'Доступна'},
        {value: 'false', display_name: 'false', label: 'Недоступна'},
    ]
    const isTaxi = [
        {value: 'true', display_name: 'true', label: 'Да'},
        {value: 'false', display_name: 'false', label: 'Нет'},
    ]

    if (!fields)
        return <ContentLoader/>

    return (
        <>
            <div className={"page"}>
                <>
                    <HeaderInside title={'Диспетчер'}/>
                    <hr/>
                    <div className="page__content">
                        <form className={"car-edit-form-container"} onSubmit={onSubmit}>
                            <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
                                {fields && fields.length > 0 && fields.map((field) => {
                                    return (
                                        <div key={field.value} className="car-booking-car-container">
                                            <input
                                                style={{display: "none"}}
                                                type="hidden"
                                                name={`cars[${field.value}].id`}
                                                value={field.value}
                                            />
                                            {field.name && <FormItem
                                                field={{
                                                    ...field.name,
                                                    placeholder: '',
                                                    name: `cars[${field.value}].name`,
                                                    type: 'text',
                                                    label: 'Название машины',
                                                    value: field.name.value,
                                                }}/>}
                                            {field.car_number && <FormItem
                                                field={{
                                                    ...field.car_number,
                                                    placeholder: '',
                                                    name: `cars[${field.value}].car_number`,
                                                    type: 'text',
                                                    label: 'Номер машины',
                                                    value: field.car_number.value,
                                                }}/>}
                                            {field.driver && <FormItem
                                                field={{
                                                    ...field.driver,
                                                    name: `cars[${field.value}].driver`,
                                                    label: 'Водитель',
                                                    type: 'select',
                                                    children: convertChoices(field.driver.choices),
                                                    value: field.driver.value?.toString(),
                                                }}/>}
                                            {field.is_active && <FormItem
                                                field={{
                                                    ...field.is_active,
                                                    placeholder: 'Выберите доступность автомобиля',
                                                    type: 'select',
                                                    children: isActive,
                                                    name: `cars[${field.value}].is_active`,
                                                    label: 'Доступна сейчас',
                                                    value: field.is_active.value?.toString(),
                                                }}/>}
                                            {field.is_taxi && <FormItem
                                                field={{
                                                    ...field.is_taxi,
                                                    placeholder: 'Выберите изпользуется ли машина в такси',
                                                    type: 'select',
                                                    children: isTaxi,
                                                    name: `cars[${field.value}].is_taxi`,
                                                    label: 'Используется в такси',
                                                    value: field.is_taxi.value?.toString(),
                                                }}/>}
                                        </div>
                                    )
                                })}

                                {(isDispatcher() || isAdmin()) && <div className="form__buttons-panel">
                                    {/*<a  onClick={e => navigateToView(1)}>ложное сохранение</a>*/}
                                    <input type="submit" id="save_button" className="d-none" value="Send"/>
                                    <label htmlFor="save_button"
                                           className={"save_button_fixed"}>{'Сохранить'}</label>
                                </div>}
                            </FormErrorsContext.Provider>
                        </form>
                        {alert && <Alert setAlert={setAlert} alert={alert}/>}
                    </div>
                </>
            </div>
        </>
    )
}
