export default [
    {
        title: '',
        pages: ['lenta', 'chat', 'dashboard', 'gigachat', 'calendar', 'my-calendar'],
        model: ['AnnouncementModel', 'ChatModel', 'dashboard', 'gigachat', 'MeetingModel'],
        id: 1,
        color: '',
    },
    {
        title: 'Корреспонденция',
        pages: ['incoming', 'outgoing', 'my-incoming', 'my-outgoing'],
        model: ['IncomingModel', 'OutputModel'],
        id: 2,
        color: '#F2994A',
    },
    {
        title: 'Договоры и служебки',
        pages: ['agreement', 'my-agreement', 'service-records', 'my-service-records', 'register', 'my-register', 'documents'],
        model: ['AgreementModel', 'ServiceRecordModel', 'DocReestrModel'],
        id: 3,
        color:'#2D9CDB',
    },
    {
        title: 'Задачи',
        pages: ['tasks', 'my-tasks', 'client-task', 'client-show'],
        model: ['TasksModel', 'ClientTaskModel'],
        id: 4,
        color: '#27AE60',
    },
    {   
        title: 'Заявки',
        pages: ['support', 'ahosupport', 'exsupport', 'capsupport', 'car-booking'],
        model: ['SupportModel', 'AhoSupportModel', 'ExploitationSupportModel', 'CapSupportModel', 'CarBookingModel', 'CarModel'],
        id: 5,
        color: '#EB5757',
    },
    {
        title: 'Участники',
        pages: ['users', 'skud', 'my-skud', 'timelog', 'my-timelog', 'counterparty', 'sms_service'],
        model: ['User', 'Skud', 'TimeLogModel', 'Contragent', 'SendSMSModel'],
        id: 6,
        color: '#BB6BD9',
    },
    {        
        title: 'Архивы',
        pages: ['digital-archive', 'digital-archive-kpk', 'archive', 'my-archive', 'invent', 'my-invent', 'aho-invent', 'my-aho-invent', 'telephony', 'my-telephony'],
        model: ['ArchiveCategory', 'digital_archive_kpk', 'ProjectDumpingModel', 'MaterialValueModel', 'AhoMaterialValueModel', 'CallModel'],
        id: 7,
        color: '#F2C94C',
    },
]

